<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/profile">User Profile</a>
    </div>
    <div class="row issue-wrapper" v-if="user._id">
      <div class="col col-auto-6 issue-col p-4 bg-white">
        <div class="row px-2">
          <div class="col-12 pt-2">
            <h6 class="text-dark text-header">
              Full Name
              <small class="text-secondary cursor-pointer float-right mt-2" v-on:click="toggleAvatar">Change avatar</small>
            </h6>
            <input class="form-control" placeholder="Full name" v-model="user.name"/>
            <br>
            <h6 class="text-dark text-header">Email Address</h6>
            <input class="form-control" placeholder="Email Address" v-model="user.email" disabled/>
            <br>
            <span v-if="user.roles === 'Customer'">
              <h6 class="text-dark text-header">Client Name</h6>
              <input class="form-control" placeholder="Client name" v-model="user.name" disabled/>
              <br>
            </span>
            <span v-if="['Manager', 'Agent'].includes(user.roles)">
              <h6 class="text-dark text-header">Business Units</h6>
              <textarea class="form-control" placeholder="Business units" v-model="user.bunit_list" disabled></textarea>
              <br>
            </span>
            <div class="row">
              <div class="col" v-if="['Manager', 'Agent'].includes(user.roles)">
                <h6 class="text-dark text-header">
                  Gitlab Token &nbsp;
                  <i class="fas fa-info-circle cursor-pointer" v-tooltip="{ content: `Login to tools.iripple.com, then go to Settings > Access Tokens. Fill up the necessary fields and generate your own Gitlab personal token.`, placement: 'right' }"></i>
                </h6>
                <input class="form-control" placeholder="Gitlab Token" v-model="user.gitlabPersonalToken"/>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Mobile Number</h6>
                <input class="form-control" placeholder="Mobile Number" v-model="user.mobileNumber"/>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Phone Number</h6>
                <input class="form-control" placeholder="Phone Number" v-model="user.phoneNumber"/>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">User Role</h6>
                <ModelSelect
                  v-model="user.roles"
                  placeholder="Select user role"
                  :options="options.roles"
                  :isDisabled="true"
                >
                </ModelSelect>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Email Notifications</h6>
                <ModelSelect
                  v-model="user.receiveEmail"
                  placeholder="Email Notifications"
                  :options="options.notifications"
                >
                </ModelSelect>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">New Password</h6>
                <input class="form-control" type="password" placeholder="Reset password" v-model="user.resetPassword"/>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Confirm Password</h6>
                <input class="form-control" type="password" placeholder="Confirm password" v-model="user.confirmPassword"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <br>
            <div class="w-100 text-right">
              <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="updateUser">
                <i class="btn p-0 text-white fas fa-save"></i>&nbsp; Update profile
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 issue-col border-md-left p-4 bg-white">
        <h6 class="py-2 text-header">Recent Activities</h6>
        <div class="p-2" v-if="histories_loading">
          <p class="text-center text-muted my-5 small">
            <i class="fas fa-spinner animate-spin"></i>&nbsp;&nbsp;Loading data
          </p>
        </div>
        <small v-else-if="!histories_loading && histories.length > 0">
          <a class="row text-left p-3 hover-light cursor-pointer border-bottom" v-for="history in histories" v-bind:key="history._id" :href="`/tickets/${history.ticket.ticketNumber}`">
            <div class="col-auto p-0" v-tooltip="history.ticket.client.name">
              <img class="activity-avatar cursor-pointer" :src="history.ticket.client.logo || $defaultIrippleLogo">
            </div>
            <div class="col p-0">
              <label class="text-property text-bold cursor-pointer">
                <span v-html="history.messages"></span><br>
                <small class="text-secondary">{{history.ticket.ticketNumber}} | {{history.createdAt | dateEstimate}}</small>
              </label>
            </div>
          </a>
        </small>
        <div class="p-2" v-else>
          <p class="text-center text-muted my-5 small">
            No recent activities&nbsp;&nbsp;<i class="fas fa-info-circle"></i>
          </p>
        </div>
      </div>
    </div>
    <modal name="avatar-menu" :transition="`fade`" :width="`620px`" :height="`auto`" :scrollable="true">
      <div class="p-5 row">
        <span v-for="avatar in $avatars" v-bind:key="avatar" class="col col-3">
          <img
            class="profile-avatar m-0 p-3"
            v-bind:class="[`/images/avatars/${avatar}` === user.avatar ? 'avatar-active' : '']"
            v-on:click="setAvatar(`/images/avatars/${avatar}`)"
            :src="$getAvatar(`/images/avatars/${avatar}`)"
          />
        </span>
      </div>
    </modal>
  </div>
</template>

<script>
  import Vue from 'vue'
  import moment from 'moment'
  import { ModelSelect } from 'vue-search-select'

  export default {
    components: {
      ModelSelect
    },
    data() {
      return {
        user: {},
        histories: [],
        histories_loading: false,
        id: this.$userData.user._id,
        options: {
          notifications: [
            { key: 'active', text: 'Receive email notifications', value: true },
            { key: 'inactive', text: 'Suspend email notifications', value: false }
          ],
          roles: [
            { key: 'Admin', text: 'Admin', value: 'Admin', disabled: true, data: {isAgent: false, role: 'Admin'} },
            { key: 'Manager', text: 'Manager', value: 'Manager', disabled: false, data: {isAgent: false, role: 'Manager'} },
            { key: 'Manager & Agent', text: 'Manager & Agent', value: 'Manager & Agent', disabled: false, data: {isAgent: true, role: 'Manager'} },
            { key: 'Agent', text: 'Agent', value: 'Agent', disabled: false, data: {isAgent: false, role: 'Agent'} },
            { key: 'Customer', text: 'Customer', value: 'Customer', disabled: false, data: {isAgent: false, role: 'Customer'} }
          ]
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      async getUser(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users/${this.id}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          await this.validateUser(response.data.isValid, response.data.exists)
          if (response.data.isValid){
            this.user = response.data.user
            this.user.bunit_list = this.user.bunit.map(r => r.name).join(', ')
            this.user.password = ''
            this.user.resetPassword = ''
            this.user.confirmPassword = ''
            this.getList()
          }
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getList(){
        this.histories_loading = true
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/tickets/list`,
            {
              tab: this.ticketTab
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.histories = response.data.histories.map(record => {
            return {
              ...record,
              messages: this.buildMessage(record)
            }
          }).filter(r => r.messages.length > 0)
          this.histories_loading = false
        } catch (error) {
          this.histories_loading = false
          console.log(error)
        }
      },
      buildMessage: function (record){
        let messages = []
        let ticket = record.ticket
        let details = record.details
        if(record.action === 'Create')
          messages.push(`Created new ticket - ${ticket.ticketNumber}`)
        else
          if(ticket && ticket.client && details && record.details.v1){
            if(details.status === 'pending' && details.iterationCount === 1)
              messages.push(`Created new ticket - ${ticket.ticketNumber}`)
            else if(details.status === 'pending' && details.iterationCount > 1){
              messages.push(`Marked ${ticket.ticketNumber} as an open issue.`)
              messages.push(`Ticket has been moved to ${details.status !== 'inProgress' ? details.status : 'in progress'}`)
            }
            else if(details.status === 'closed')
              messages.push(`Closed ticket ${ticket.ticketNumber}`)
            else if(details.status && details.status !== 'closed')
              messages.push(`Changed status of ${ticket.ticketNumber} to ${details.status !== 'inProgress' ? details.status : 'in progress'}`)
            if(details.agent && details.pre && details.pre.agent && record.agent && record.agent._id != details.pre.agent._id)
              messages.push(`Reassigned ${ticket.ticketNumber} to ${record.agent.name}`)
          }
        return messages.join('<br>')
      },
      validateUser: async function(isValid, exists){
        if(!isValid){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: exists ? `Something went wrong, try again.` : `Account information cannot be found`
          })
          await this.$sleep(500);
          this.$router.push('/')
        }
      },
      toggleAvatar: function(){
        this.$modal.toggle('avatar-menu');
      },
      setAvatar: function(avatar){
        this.user.avatar = avatar
        this.toggleAvatar()
      },
      updateUser: function (){
        this.errors = []
        if(this.user.name.trim() === '')
          this.errors.push('Name cannot be empty')
        if(this.user.email.trim() === '')
          this.errors.push('Email address cannot be empty')
        if(this.user.roles !== 'Customer' && this.user.gitlabPersonalToken.trim() === '')
          this.errors.push('Gitlab token cannot be empty')
        if(this.user.resetPassword.trim().length > 0){
          if(this.user.resetPassword.trim().length < 6)
            this.errors.push('Password must have 6 or more characters')
          if(this.user.confirmPassword.trim() !== this.user.resetPassword.trim())
            this.errors.push('Confirm password does not match new password')
        }
        if (this.errors.length > 0){
          this.errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'User Profile Update',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.submitUser()
        }
      },
      async submitUser(){
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/users/${this.id}`,
            {
              user: {
                name: this.user.name ? this.user.name.trim() : '',
                phoneNumber: this.user.phoneNumber ? this.user.phoneNumber.trim() : '',
                mobileNumber: this.user.mobileNumber ? this.user.mobileNumber.trim() : '',
                receiveEmail: this.user.receiveEmail,
                resetPassword: this.user.resetPassword.trim() === "" ? null : this.user.resetPassword.trim(),
                gitlabPersonalToken: this.user.gitlabPersonalToken,
                avatar: this.user.avatar
              },
              _id: this.id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.user && response.data.user._id) {
            let userData = this.$userData
            userData.user = response.data.user
            userData.user.role = userData.user.roles
            userData.gitlabToken = userData.user.gitlabPersonalToken
            localStorage.setObject('userData', userData)
            Vue.prototype.$userData = userData
            this.getUser()
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: 'Your changes have been saved.'
            })
          }
          this.loader(false)
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
        }
      },
    },
    filters: {
      dateEstimate(date) {
        return moment(date, 'YYYY/MM/DD HH:mm:ss').fromNow();
      }
    },
    mounted: function() {
      document.title = `User Profile | iRipple Helpdesk`;
      this.getUser()
    }
  }
</script>
